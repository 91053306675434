.ticket-img {
    width: 40px;
    height: 40px;
    border: 2px solid silver;
    overflow: hidden;
}
.circle-img {
    height: 100%;
    transform: translateX(-50%);
    margin-left: 50%;
}